.firebaseui-button, .firebaseui-idp-button {
	border-radius: 5px !important;
	font-weight: bold !important;
	box-shadow: none !important
}

.firebaseui-idp-button.firebaseui-idp-google {
	border: 1px solid #e4eff5
}

.firebaseui-idp-button.firebaseui-idp-password,
.mdl-button--raised.mdl-button--colored,
.mdl-progress.mdl-progress--indeterminate>.bar1, .mdl-progress.mdl-progress__indeterminate>.bar1,
.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
	background-color: #8d53a2 !important;
}

.mdl-button--primary.mdl-button--primary {
	color: #8d53a2 !important;
}

.mdl-shadow--2dp { 
	box-shadow: none !important;
}

.firebaseui-card-header {
	display: none;
}